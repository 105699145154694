import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoParana from "../../assets/old/img/logos-ase/parana-seguros.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="parana">
        <Helmet>
            <title>Parana Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia Parana Seguros: 0800-222-7272."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoParana}
                    alt="Parana Seguros"
                />
                <h4 className="lead post-title text-muted">Parana Seguros</h4>
                <p className="">Servicio de asistencia: 0800-222-7272.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
          Ante cualquier consulta y/o gestión referente al reclamo presentado, dirigirse a las siguientes direcciones de e-mail: cis@paranaseguros.com.ar del Centro Integral de Servicios de la Cía.
        </p>
        <h6>Reclamo de terceros</h6>
        <p>
            <strong>Atención personalizada</strong><br/>
            Lunes y miércoles de 10.30 a 12.30 y de 14 a 17 hs<br/>
            Tte. Gral. Juan Domingo Perón 715 CABA
        </p>
        <p>
            <strong>Atención telefónica</strong><br/>
            Martes, jueves y viernes de 10.15 a 13 y 14 a 18 hs.<br/>
            cis@paranaseguros.com.ar
        </p>


        <hr className="pb-4" />



    </SiniestrosLayout>
)
